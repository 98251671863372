import React from 'react';
import ReactDOM from 'react-dom';
import {reloadOnChanges} from './main/lib/dev.reload';
import {FrontApp} from './main/routes/FrontApp';
import {EdstartVersion} from './main/config/version';

console.log("[Front plugin]: Loading...", EdstartVersion);
window['edstartVersion'] = EdstartVersion;
ReactDOM.render(React.createElement(FrontApp), document.getElementById('root'));
reloadOnChanges('Front plugin');
